import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Section, GridList, Body1 } from '@class101/ui';
import { Playground, Props } from 'docz';
import { imageSrc } from './interface';
import { PlayGroundBanner } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "section"
    }}>{`Section`}</h1>
    <p>{`titleStyle은 Typography, buttonStyle은 Button 컴포넌트의 속성을 참고해주세요.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Section} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`섹션은 헤더를 반드시 가지고 있어야 합니다.`}</p>
    <Playground __position={1} __code={'<Section title=\"가장 완강률이 높은 클래스\">\n  <PlayGroundBanner>\n    리스트나 캐러셀 등이 들어갈 수 있습니다.\n  </PlayGroundBanner>\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      GridList,
      Body1,
      Playground,
      Props,
      imageSrc,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="가장 완강률이 높은 클래스" mdxType="Section">
    <PlayGroundBanner mdxType="PlayGroundBanner">리스트나 캐러셀 등이 들어갈 수 있습니다.</PlayGroundBanner>
  </Section>
    </Playground>
    <h2 {...{
      "id": "anchor-or-action-to-title"
    }}>{`Anchor or Action to Title`}</h2>
    <p><inlineCode parentName="p">{`to`}</inlineCode>{`, `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`을 지정할 경우 섹션 헤더는 액션이 가능한 요소가 됩니다.`}<br parentName="p"></br>{`
`}{`BreakPoint에 따라 'lg'에서는 오버시에 나오고 'sm'에서는 항상 보이는 요소입니다.`}</p>
    <Playground __position={2} __code={'<Section title=\"가장 완강률이 높은 클래스\" to=\"/\">\n  <GridList\n    items={[\n      {\n        id: 1,\n        title: \'리스트 아이템 1\',\n        image: imageSrc,\n        to: \'/\',\n      },\n      {\n        id: 2,\n        title: \'리스트 아이템 2\',\n        image: imageSrc,\n        to: \'/\',\n      },\n    ]}\n    smColumn={2}\n    renderItem={item => {\n      const { title, image } = item\n      return (\n        <div>\n          <div>\n            <img style={{ width: \'100%\' }} src={image} alt={title} />\n          </div>\n          <Body1>{title}</Body1>\n        </div>\n      )\n    }}\n  />\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      GridList,
      Body1,
      Playground,
      Props,
      imageSrc,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="가장 완강률이 높은 클래스" to="/" mdxType="Section">
    <GridList items={[{
          id: 1,
          title: '리스트 아이템 1',
          image: imageSrc,
          to: '/'
        }, {
          id: 2,
          title: '리스트 아이템 2',
          image: imageSrc,
          to: '/'
        }]} smColumn={2} renderItem={item => {
          const {
            title,
            image
          } = item;
          return <div>
            <div>
              <img style={{
                width: '100%'
              }} src={image} alt={title} />
            </div>
            <Body1 mdxType="Body1">{title}</Body1>
          </div>;
        }} mdxType="GridList" />
  </Section>
    </Playground>
    <h2 {...{
      "id": "with-description"
    }}>{`with description`}</h2>
    <Playground __position={3} __code={'<Section\n  title=\"가장 완강률이 높은 클래스\"\n  description=\"알림신청 하신 분들에게는 할인 및 이벤트 소식과 제작 과정을 전해드려요.\"\n  buttonTitle=\"클래스 전체보기\"\n>\n  <GridList\n    items={[\n      {\n        id: 1,\n        title: \'리스트 아이템 1\',\n        image: imageSrc,\n      },\n      {\n        id: 2,\n        title: \'리스트 아이템 2\',\n        image: imageSrc,\n      },\n    ]}\n    smColumn={2}\n    renderItem={item => {\n      const { title, image } = item\n      return (\n        <div>\n          <div>\n            <img style={{ width: \'100%\' }} src={image} alt={title} />\n          </div>\n          <Body1>{title}</Body1>\n        </div>\n      )\n    }}\n  />\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      GridList,
      Body1,
      Playground,
      Props,
      imageSrc,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="가장 완강률이 높은 클래스" description="알림신청 하신 분들에게는 할인 및 이벤트 소식과 제작 과정을 전해드려요." buttonTitle="클래스 전체보기" mdxType="Section">
    <GridList items={[{
          id: 1,
          title: '리스트 아이템 1',
          image: imageSrc
        }, {
          id: 2,
          title: '리스트 아이템 2',
          image: imageSrc
        }]} smColumn={2} renderItem={item => {
          const {
            title,
            image
          } = item;
          return <div>
            <div>
              <img style={{
                width: '100%'
              }} src={image} alt={title} />
            </div>
            <Body1 mdxType="Body1">{title}</Body1>
          </div>;
        }} mdxType="GridList" />
  </Section>
    </Playground>
    <h2 {...{
      "id": "custom-style"
    }}>{`Custom Style`}</h2>
    <p>{`기본 typography의 변경은 `}<inlineCode parentName="p">{`titleStyle={{ typography: "Body2" }}`}</inlineCode>{`처럼 넣어주세요.`}</p>
    <Playground __position={4} __code={'<Section\n  title=\"가장 완강률이 높은 클래스\"\n  titleStyle={{\n    typography: \'Body2\',\n    color: \'orange\',\n    sm: \'Caption1\',\n  }}\n>\n  <GridList\n    items={[\n      {\n        id: 1,\n        title: \'리스트 아이템 1\',\n        image: imageSrc,\n      },\n      {\n        id: 2,\n        title: \'리스트 아이템 2\',\n        image: imageSrc,\n      },\n    ]}\n    smColumn={2}\n    renderItem={item => {\n      const { title, image } = item\n      return (\n        <div>\n          <div>\n            <img style={{ width: \'100%\' }} src={image} alt={title} />\n          </div>\n          <Body1>{title}</Body1>\n        </div>\n      )\n    }}\n  />\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      GridList,
      Body1,
      Playground,
      Props,
      imageSrc,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="가장 완강률이 높은 클래스" titleStyle={{
        typography: 'Body2',
        color: 'orange',
        sm: 'Caption1'
      }} mdxType="Section">
    <GridList items={[{
          id: 1,
          title: '리스트 아이템 1',
          image: imageSrc
        }, {
          id: 2,
          title: '리스트 아이템 2',
          image: imageSrc
        }]} smColumn={2} renderItem={item => {
          const {
            title,
            image
          } = item;
          return <div>
            <div>
              <img style={{
                width: '100%'
              }} src={image} alt={title} />
            </div>
            <Body1 mdxType="Body1">{title}</Body1>
          </div>;
        }} mdxType="GridList" />
  </Section>
    </Playground>
    <h2 {...{
      "id": "with-linktext"
    }}>{`with LinkText`}</h2>
    <p><inlineCode parentName="p">{`to`}</inlineCode>{`, `}<inlineCode parentName="p">{`href`}</inlineCode>{`, `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`와 함께 지정할 경우 같은 액션을 취하는 텍스트 링크/버튼이 추가됩니다.`}<br parentName="p"></br>{`
`}{`BreakPoint에 따라 'sm'에서는 사라집니다.`}</p>
    <Playground __position={5} __code={'<Section\n  title=\"가장 완강률이 높은 클래스\"\n  description=\"알림신청 하신 분들에게는 할인 및 이벤트 소식과 제작 과정을 전해드려요.\"\n  to=\"/\"\n  linkText=\"전체 클래스 보기\"\n>\n  <GridList\n    items={[\n      {\n        id: 1,\n        title: \'리스트 아이템 1\',\n        image: imageSrc,\n        to: \'/\',\n      },\n      {\n        id: 2,\n        title: \'리스트 아이템 2\',\n        image: imageSrc,\n        to: \'/\',\n      },\n    ]}\n    smColumn={2}\n    renderItem={item => {\n      const { title, image } = item\n      return (\n        <div>\n          <div>\n            <img style={{ width: \'100%\' }} src={image} alt={title} />\n          </div>\n          <Body1>{title}</Body1>\n        </div>\n      )\n    }}\n  />\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      GridList,
      Body1,
      Playground,
      Props,
      imageSrc,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="가장 완강률이 높은 클래스" description="알림신청 하신 분들에게는 할인 및 이벤트 소식과 제작 과정을 전해드려요." to="/" linkText="전체 클래스 보기" mdxType="Section">
    <GridList items={[{
          id: 1,
          title: '리스트 아이템 1',
          image: imageSrc,
          to: '/'
        }, {
          id: 2,
          title: '리스트 아이템 2',
          image: imageSrc,
          to: '/'
        }]} smColumn={2} renderItem={item => {
          const {
            title,
            image
          } = item;
          return <div>
            <div>
              <img style={{
                width: '100%'
              }} src={image} alt={title} />
            </div>
            <Body1 mdxType="Body1">{title}</Body1>
          </div>;
        }} mdxType="GridList" />
  </Section>
    </Playground>
    <Playground __position={6} __code={'<Section\n  title=\"가장 완강률이 높은 클래스\"\n  description=\"알림신청 하신 분들에게는 할인 및 이벤트 소식과 제작 과정을 전해드려요.\"\n  to=\"https://ui.class101.dev\"\n  external\n  linkText=\"전체 클래스 보기\"\n>\n  <GridList\n    items={[\n      {\n        id: 1,\n        title: \'리스트 아이템 1\',\n        image: imageSrc,\n        to: \'/\',\n      },\n      {\n        id: 2,\n        title: \'리스트 아이템 2\',\n        image: imageSrc,\n        to: \'/\',\n      },\n    ]}\n    smColumn={2}\n    renderItem={item => {\n      const { title, image } = item\n      return (\n        <div>\n          <div>\n            <img style={{ width: \'100%\' }} src={image} alt={title} />\n          </div>\n          <Body1>{title}</Body1>\n        </div>\n      )\n    }}\n  />\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      GridList,
      Body1,
      Playground,
      Props,
      imageSrc,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="가장 완강률이 높은 클래스" description="알림신청 하신 분들에게는 할인 및 이벤트 소식과 제작 과정을 전해드려요." to="https://ui.class101.dev" external linkText="전체 클래스 보기" mdxType="Section">
    <GridList items={[{
          id: 1,
          title: '리스트 아이템 1',
          image: imageSrc,
          to: '/'
        }, {
          id: 2,
          title: '리스트 아이템 2',
          image: imageSrc,
          to: '/'
        }]} smColumn={2} renderItem={item => {
          const {
            title,
            image
          } = item;
          return <div>
            <div>
              <img style={{
                width: '100%'
              }} src={image} alt={title} />
            </div>
            <Body1 mdxType="Body1">{title}</Body1>
          </div>;
        }} mdxType="GridList" />
  </Section>
    </Playground>
    <Playground __position={7} __code={'<Section\n  title=\"가장 완강률이 높은 클래스\"\n  description=\"알림신청 하신 분들에게는 할인 및 이벤트 소식과 제작 과정을 전해드려요.\"\n  onClick={() => alert(\'Clicked!!\')}\n  linkText=\"전체 클래스 보기\"\n>\n  <GridList\n    items={[\n      {\n        id: 1,\n        title: \'리스트 아이템 1\',\n        image: imageSrc,\n        to: \'/\',\n      },\n      {\n        id: 2,\n        title: \'리스트 아이템 2\',\n        image: imageSrc,\n        to: \'/\',\n      },\n    ]}\n    smColumn={2}\n    renderItem={item => {\n      const { title, image } = item\n      return (\n        <div>\n          <div>\n            <img style={{ width: \'100%\' }} src={image} alt={title} />\n          </div>\n          <Body1>{title}</Body1>\n        </div>\n      )\n    }}\n  />\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      GridList,
      Body1,
      Playground,
      Props,
      imageSrc,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="가장 완강률이 높은 클래스" description="알림신청 하신 분들에게는 할인 및 이벤트 소식과 제작 과정을 전해드려요." onClick={() => alert('Clicked!!')} linkText="전체 클래스 보기" mdxType="Section">
    <GridList items={[{
          id: 1,
          title: '리스트 아이템 1',
          image: imageSrc,
          to: '/'
        }, {
          id: 2,
          title: '리스트 아이템 2',
          image: imageSrc,
          to: '/'
        }]} smColumn={2} renderItem={item => {
          const {
            title,
            image
          } = item;
          return <div>
            <div>
              <img style={{
                width: '100%'
              }} src={image} alt={title} />
            </div>
            <Body1 mdxType="Body1">{title}</Body1>
          </div>;
        }} mdxType="GridList" />
  </Section>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      